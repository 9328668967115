<template>
  <div class="my">
    <div class="user-box flex ai-c jc-sb">
      <div class="flex ai-c">
        <img class="user-pic"
             :src="'/img/portrait/'+portrait+'.png'"
             alt="">
        <div class="phone">{{info.name}}</div>
      </div>
      <div class="replace-img flex ai-c jc-c"
           @click="$router.push('/my/portrait')">
        更换头像 <img src="/img/more.png"
             alt="">
      </div>
    </div>
    <v-orderNav :orderNav="orderNav1"
                title="市民超市"
                path="/my/orderListSMCS" />
    <!-- <v-orderNav :orderNav="orderNav2" title="本地生活" path="/my/orderListBDSH" /> -->
    <div class="service">
      <div class="title">我的服务</div>
      <div class="list flex fw-w">
        <div class="item flex ai-c"
             v-for="(item, index) in serviceNav"
             @click="Click(item)"
             :key="index">
          <img :src="item.icon"
               alt="">
          <div class="flex fd-c">
            <div class="t1">{{item.name}}</div>
            <div class="t2"
                 v-html="item.txt"></div>
          </div>
        </div>
      </div>
    </div>
    <v-tabbar :active="3" />
  </div>
</template>

<script>
import tabbar from '@/components/tabbar'
import orderNav from './orderNav'
import { userInfo, orderCount } from '../../api'
export default {
  components: {
    'v-tabbar': tabbar,
    'v-orderNav': orderNav,
  },
  props: {},
  data() {
    return {
      info: {},
      orderNav1: [{
        icon: '/img/icon-1.png',
        name: '待支付',
        number: 0,
        query: { type: 0 }
      }, {
        icon: '/img/icon-2.png',
        name: '待发货',
        number: 0,
        query: { type: 1 }
      }, {
        icon: '/img/icon-3.png',
        name: '待收货',
        number: 0,
        query: { type: 2 }
      },
      {
        icon: '/img/icon-4.png',
        name: '售后',
        path: '/my/afterService',
      },
      {
        icon: '/img/icon-5.png',
        name: '全部订单',
        number: 0,
        query: { type: '' }
      }],
      orderNav2: [{
        icon: '/img/icon-1.png',
        name: '待支付'
      }, {
        icon: '/img/icon-6.png',
        name: '待使用'
      }, {
        icon: '/img/icon-7.png',
        name: '待收货'
      }, {
        icon: '/img/icon-4.png',
        name: '已完成'
      }, {
        icon: '/img/icon-5.png',
        name: '全部订单'
      }],
      serviceNav: [{
        icon: '/img/icon-service1.png',
        name: '收货地址',
        txt: '我的地址管理',
        path: '/my/addressList'
      }, {
        icon: '/img/icon-service2.png',
        name: '我的收藏',
        txt: '个人的收藏好物',
        path: '/my/collection'
      },
      {
        icon: '/img/icon-service3.png',
        name: '优惠券',
        txt: '共有<font>0</font>张优惠券',
        path: '/my/coupons'
      },
      {
        icon: '/img/icon-service4.png',
        name: '联系客服',
        txt: '9:00~18:00在线',
        path: '/my/service'
      },
      {
        icon: '/img/icon-fp.png',
        name: '开具发票',
        txt: '电子发票',
        path: '/my/invoice'
      },
      {
        icon: '/img/icon-more.png',
        name: '更多服务',
        txt: '敬请期待',
      },
        // {
        //     icon: '/img/icon-service5.png',
        //     name: '常见问题',
        //     txt: '有问题来这里',
        //     path: '/my/commonProblem'
        // },{
        //     icon: '/img/icon-service6.png',
        //     name: '意见反馈',
        //     txt: '为您处理跟进',
        //     path: '/my/opinion'
        // }
      ],
      portrait: 0
    }
  },
  watch: {},
  computed: {},
  methods: {
    userInfo() {
      userInfo({}).then(res => {
        this.info = res.data.data
        this.serviceNav[2].txt = `共有<font>${res.data.data.couponCnt}</font>张优惠券`
        this.$store.state.newGift = res.data.data.newGift
        console.log(this.$store.state);
      })
    },
    orderCount() {
      orderCount({}).then(res => {
        var data = res.data.data
        this.orderNav1[0].number = data.waitPay
        this.orderNav1[1].number = data.waitPick
        this.orderNav1[2].number = data.waitReceive
      })
    },
    Click(item) {
      if (item.path) {
        this.$router.push(item.path)
      } else {
        this.$toast('开发中，敬请期待')
      }
    }
  },
  created() {
    var portrait = localStorage.getItem('portrait')
    if (portrait != null) {
      this.portrait = portrait
    }
    this.userInfo()
    this.orderCount()
  },
  mounted() { }
}
</script>
<style scoped>
.my {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 1.4rem;
  background-image: url(/img/my-bg.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: RGBA(238, 238, 238, 1);
}
.user-box {
  margin-top: 0.27rem;
  margin-bottom: 0.35rem;
}
.user-box .user-pic {
  width: 1.36rem;
  height: 1.36rem;
  background: #ffffff;
  border: 0.03rem solid #fdb07e;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 0.2rem;
}
.user-box .phone {
  font-size: 0.36rem;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
  margin-left: 0.1rem;
}
.user-box .replace-img {
  width: 1.54rem;
  height: 0.48rem;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0.24rem;
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 0.2rem;
}
.user-box .replace-img img {
  width: 0.12rem;
  height: 0.22rem;
  margin-left: 0.1rem;
}
.service {
  width: 7.1rem;
  background: #ffffff;
  border-radius: 0.14rem;
  margin-left: 0.2rem;
  margin-top: 0.25rem;
}
.service .title {
  line-height: 0.86rem;
  margin-left: 0.2rem;
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
}
.service .list {
  width: 100%;
}
.service .list .item {
  width: 50%;
  height: 1.58rem;
  box-sizing: border-box;
  border-top: 1px solid #ededed;
}
.service .list .item:nth-child(odd) {
  border-right: 1px solid #ededed;
}
.service .list .item img {
  width: 0.48rem;
  height: 0.48rem;
  object-fit: scale-down;
  margin-right: 0.38rem;
  margin-left: 0.47rem;
}
.service .list .item .t1 {
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #282828;
}
.service .list .item .t2 {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #969696;
  margin-top: 0.1rem;
}
.service .list .item .t2 >>> font {
  color: rgba(235, 63, 49, 1);
}
</style>