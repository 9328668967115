<template>
  <div class="orderNav flex fd-c ai-c">
    <div class="title">{{title}}</div>
    <div class="list flex ai-c">
      <div class="item flex fd-c ai-c jc-c" v-for="(item, index) in orderNav" @click="$router.push({path:item.path?item.path:path,query:item.query})" :key="index">
        <img :src="item.icon" alt="" />
        <div class="name">{{ item.name }}</div>
        <div class="nb" :style="orderNav.length==4&&'left: 1rem;'" v-show="item.number>0">{{item.number}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["orderNav","title","path"],
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style scoped>
.orderNav {
    width: 7.10rem;
    background: #FFFFFF;
    border-radius: .14rem;
    margin-left: .2rem;
    margin-top: .25rem;
}
.orderNav .title{
    line-height: .79rem;
    width: 6.7rem;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    border-bottom: 1px solid #ededed;
}
.orderNav .list{
    width: 100%;
    height: 1.5rem;
}
.orderNav .list .item{
    position: relative;
    flex: 1;
    height: 100%;
}
.orderNav .list .item img{
    width: .5rem;
    height: .5rem;
    object-fit:contain;
}
.orderNav .list .item .name{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #666666;
    margin-top: .2rem;
}
.orderNav .list .item .nb{
    position: absolute;
    top: .09rem;
    left: .8rem;
    font-size: .18rem;
    line-height: .18rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF6422;
    border-radius: .8rem;
    padding: 0.03rem .055rem;
    border: 2px solid #FD6926;
    box-sizing: border-box;
    background-color: #fff;
}

</style>