<template>
  <van-tabbar :value="active" z-index="2000" safe-area-inset-bottom active-color="rgba(243, 94, 58, 1)">
    <van-tabbar-item
      v-for="(item, index) in tabbar"
      @click="tabbarClick(item)"
      :key="index"
    >
      <span>{{ item.name }}</span>
      <template #icon="props">
        <img :src="props.active ? item.active : item.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  components: {},
  props: ["active"],
  data() {
    return {
      tabbar: [
        {
          active: "/img/icon-home-active.png",
          inactive: "/img/icon-home.png",
          name: "首页",
          path: "/home",
        },
        {
          active: "/img/icon-category-active.png",
          inactive: "/img/icon-category.png",
          name: "榜单",
          path: "/classification",
        },
        {
          active: "/img/icon-cart-active.png",
          inactive: "/img/icon-cart.png",
          name: "购物车",
          path: "/cart",
        },
        {
          active: "/img/icon-my-active.png",
          inactive: "/img/icon-my.png",
          name: "我的",
          path: "/my",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    tabbarClick(item) {
      if (item.path!=this.$route.path) {
        this.$router.replace(item.path)
      }
    }
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.tabbar {
  height: .97rem;
}
.tabbar span {
  font-size: 0.2rem;
  font-family: PingFang;
  font-weight: bold;
  color: #636363;
}
.van-tabbar-item__icon img {
  height: 0.36rem;
}
</style>